import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { Loader, Table } from "components";
import { useHttp } from "hooks";
import ModalService from "components/modal/ModalService";
import TransferConsignmentModel from "./TransferConsignmentModel";

const ListDevices = () => {
  const [rowCount, setRowCount] = useState(0);
  const [channelData, setChannels] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [warrentydData, setWarrentydData] = useState([]);
  const [Warehouses, setWarehouses] = useState([]);
  const { getWerror, getWloading, sendHttpRequest: getWarehouses } = useHttp();
  const { error, loading, sendHttpRequest: getAccessories } = useHttp();
  const [selectedRepairRequest, setSelectedRepairRequest] = useState([]);

  useEffect(() => {
    setRowCount(channelData?.length);
    getAllWarrentyData(channelData);
  }, [channelData]);

  useEffect(() => {
    setRowCount(filteredData?.length);
  }, [filteredData]);

  useEffect(() => {
    getAllChannels();
    getWarehouses(
      "get_all_warehouses",
      "GET",
      null,
      ({ data: { warehouse } }) => {
        setWarehouses(warehouse);
      }
    );
  }, []);

  const getAllChannels = () => {
    getAccessories("get_accessories", "GET", null, ({ data: { find } }) =>
      setChannels(find)
    );
  };

  function getAllWarrentyData(devices) {
    if (!devices) {
      return [];
    }
    const tempdata = devices?.map((val) => ({
      name: val?.sku?.name || "-", // Safe navigation for `model`
      quantity: val?.quantity || 0,
      soldQuantity: val?.soldQuantity || 0,
      dispatchedQuantity: val?.dispatchedQuantity || 0,
      createdAt: val?.createdAt || "-",
      country: val?.country ? val?.country?.toUpperCase() : "-",
      _id: val?._id,
      serialName: val?.name,
      stockId: val?.stockId || "-", // Default to false if undefined
    }));

    setWarrentydData(tempdata);
  }

  const columns = [
    {
      label: "Name",
      name: "name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>{value}</div>
            </>
          );
        },
      },
    },
    {
      label: "Serial",
      name: "serialName",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>{value}</div>
            </>
          );
        },
      },
    },
    {
      label: " Quantity",
      name: "quantity",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
      },
    },
    {
      label: "Sold Quantity",
      name: "soldQuantity",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
      },
    },
    {
      label: "Dispatched Quantity",
      name: "dispatchedQuantity",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
      },
    },
    {
      label: "Country",
      name: "country",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
      },
    },
    {
      label: "Date",
      name: "createdAt",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? format(new Date(value), "PP") : "-";
        },
      },
    },
  ];
  const handleRowSelectionChange = (currentRowsSelected, allRowsSelected) => {
    const selectedData = allRowsSelected?.map(
      ({ dataIndex }) => channelData[dataIndex]
    );
    let tempObjData = [];
    selectedData?.map((data, zind) => {
      tempObjData.push({
        details_id: data?._id,
        quantity: data?.quantity,
        name: data?.sku?.name,
        dispatchedQuantity:data?.dispatchedQuantity,
        stockId: data?.stockId,
      });
    });
    setSelectedRepairRequest(tempObjData);
  };

  const openTransferConsignmentModel = (repairData) => {
    ModalService.open(TransferConsignmentModel, {
      refresh: () => getAllChannels(),
      selectedRepairRequest: repairData,
      setSelectedRepairRequest,
      Warehouses: Warehouses,
    });
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          <Table
            title={"Accessories"}
            data={warrentydData}
            columns={columns}
            option={{
              customToolbar: () => (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      marginBottom: "16px",
                      fontSize: "18px",
                      marginLeft: "-58px",
                    }}
                  >
                    Total Row Count: <b>{rowCount}</b>
                  </div>
                </div>
              ),
              onTableChange: (action, tableState) => {
                if (action === "filterChange" || action === "search") {
                  setFilteredData(
                    tableState.displayData.map((row) => row.data)
                  );
                }
              },
              // selectableRows: "multiple",
              // onRowSelectionChange: handleRowSelectionChange,
              // customToolbarSelect: (
              //   selectedRows,
              //   displayData,
              //   setSelectedRows
              // ) => (
              //   <div>
              //     <button
              //       type="button"
              //       class="btn btn-sm btn-primary"
              //       onClick={() =>
              //         openTransferConsignmentModel(selectedRepairRequest)
              //       }
              //     >
              //       Transfer Consignment
              //     </button>
              //     &emsp;
              //   </div>
              // ),
            }}
          />
        </>
      )}
    </>
  );
};

export default ListDevices;
