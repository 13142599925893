import React from "react";
import { Link } from "react-router-dom";
import Logo from "assets/images/badili-logo.png";
import "../../../src/assets/js/app.js";

const MainNavbar = ({ onToggleClick }) => {
  let currentUser = JSON.parse(sessionStorage.getItem("user"));
  return (
    // <div className="navbar navbar-expand-md navbar-dark bg-indigo fixed-top">
    <div className="navbar navbar-expand-md bg-white fixed-top">
      {currentUser?.country.toLowerCase() === "ke"
        ? "🇰🇪"
        : currentUser?.country.toLowerCase() === "tz"
        ? "🇹🇿"
        : currentUser?.country.toLowerCase() === "ug"
        ? "🇺🇬"
        : currentUser?.country.toLowerCase() === "ng"
        ? "🇳🇬"
        : currentUser?.country.toLowerCase() === "in"
        ? "🇮🇳"
        : ""}
      <div className="navbar-brand">
        <Link to="/inventory/products" className="d-inline-block">
          <img
            src={Logo}
            alt="Logo"
            style={{
              width: "4rem",
              height: "3rem",
            }}
          />
        </Link>
      </div>
      <div className="d-md-none">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar-mobile"
        >
          <i className="icon-tree5" />
        </button>
        <button
          className="navbar-toggler sidebar-mobile-main-toggle"
          type="button"
        >
          <i className="icon-paragraph-justify3" />
        </button>
      </div>
      <div className="collapse navbar-collapse" id="navbar-mobile">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              href="#"
              onClick={onToggleClick}
              className="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block"
            >
              <i className="icon-paragraph-justify3" />
            </a>
          </li>
        </ul>
        {/* <span className="navbar-text ml-md-3">
          <span className="badge badge-mark border-orange-300 mr-2" />
          Morning, {currentUser.fname}
        </span> */}
        <ul className="navbar-nav ml-md-auto">
          <li className="nav-item dropdown">
            <a
              href="#"
              className="navbar-nav-link dropdown-toggle"
              data-toggle="dropdown"
            >
              <i className="icon-bell2 mr-2" />
              Notifications
            </a>
            <div className="dropdown-menu dropdown-menu-right dropdown-content wmin-md-350">
              <div className="dropdown-content-header">
                <span className="font-size-sm line-height-sm text-uppercase font-weight-semibold">
                  Latest activity
                </span>
                <a href="#" className="text-default">
                  <i className="icon-search4 font-size-base" />
                </a>
              </div>
              <div className="dropdown-content-body dropdown-scrollable">
                <ul className="media-list">
                  <li className="media">
                    <div className="mr-3">
                      <a
                        href="#"
                        className="btn bg-success-400 rounded-round btn-icon"
                      >
                        <i className="icon-mention" />
                      </a>
                    </div>
                    <div className="media-body">
                      <a href="#">Taylor Swift</a> mentioned you in a post
                      "Angular JS. Tips and tricks"
                      <div className="font-size-sm text-muted mt-1">
                        4 minutes ago
                      </div>
                    </div>
                  </li>
                  <li className="media">
                    <div className="mr-3">
                      <a
                        href="#"
                        className="btn bg-pink-400 rounded-round btn-icon"
                      >
                        <i className="icon-paperplane" />
                      </a>
                    </div>
                    <div className="media-body">
                      Special offers have been sent to subscribed users by{" "}
                      <a href="#">Donna Gordon</a>
                      <div className="font-size-sm text-muted mt-1">
                        36 minutes ago
                      </div>
                    </div>
                  </li>
                  <li className="media">
                    <div className="mr-3">
                      <a
                        href="#"
                        className="btn bg-blue rounded-round btn-icon"
                      >
                        <i className="icon-plus3" />
                      </a>
                    </div>
                    <div className="media-body">
                      <a href="#">Chris Arney</a> created a new{" "}
                      <span className="font-weight-semibold">Design</span>{" "}
                      branch in{" "}
                      <span className="font-weight-semibold">Limitless</span>{" "}
                      repository
                      <div className="font-size-sm text-muted mt-1">
                        2 hours ago
                      </div>
                    </div>
                  </li>
                  <li className="media">
                    <div className="mr-3">
                      <a
                        href="#"
                        className="btn bg-purple-300 rounded-round btn-icon"
                      >
                        <i className="icon-truck" />
                      </a>
                    </div>
                    <div className="media-body">
                      Shipping cost to the Netherlands has been reduced,
                      database updated
                      <div className="font-size-sm text-muted mt-1">
                        Feb 8, 11:30
                      </div>
                    </div>
                  </li>
                  <li className="media">
                    <div className="mr-3">
                      <a
                        href="#"
                        className="btn bg-warning-400 rounded-round btn-icon"
                      >
                        <i className="icon-comment" />
                      </a>
                    </div>
                    <div className="media-body">
                      New review received on{" "}
                      <a href="#">Server side integration</a> services
                      <div className="font-size-sm text-muted mt-1">
                        Feb 2, 10:20
                      </div>
                    </div>
                  </li>
                  <li className="media">
                    <div className="mr-3">
                      <a
                        href="#"
                        className="btn bg-teal-400 rounded-round btn-icon"
                      >
                        <i className="icon-spinner11" />
                      </a>
                    </div>
                    <div className="media-body">
                      <strong>January, 2018</strong> - 1320 new users, 3284
                      orders, $49,390 revenue
                      <div className="font-size-sm text-muted mt-1">
                        Feb 1, 05:46
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="dropdown-content-footer bg-light">
                <a
                  href="#"
                  className="font-size-sm line-height-sm text-uppercase font-weight-semibold text-grey mr-auto"
                >
                  All activity
                </a>
                <div>
                  <a
                    href="#"
                    className="text-grey"
                    data-popup="tooltip"
                    title="Clear list"
                  >
                    <i className="icon-checkmark3" />
                  </a>
                  <a
                    href="#"
                    className="text-grey ml-2"
                    data-popup="tooltip"
                    title="Settings"
                  >
                    <i className="icon-gear" />
                  </a>
                </div>
              </div>
            </div>
          </li>
          <li className="nav-item">
            <a href="/">
              <button className="navbar-nav-link bg-transparent border-0 outline-0">
                <i className="icon-switch2" />
                <span className="d-md-none ml-2">Logout</span>
              </button>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MainNavbar;
