export const thousandCommaSeparator = (number) => {
  let nf = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: (() => {
      const userCountry = JSON.parse(sessionStorage.getItem("user"))?.country;
      switch (userCountry) {
        case "ug":
          return "UGX";
        case "tz":
          return "TZS";
        case "ke":
          return "KES";
        case "in":
          return "INR";
        case "ng":
          return "NGN";
        default:
          return "";
      }
    })(),
  });

  return nf.format(number);
};
