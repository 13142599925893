import ListDevices from "./ListDevices";

const AccessoriesRoutes = [
  {
    path: "/accessories",
    element: <ListDevices />,
  },
];

export default AccessoriesRoutes;
