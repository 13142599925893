import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const RecoverPassword = () => {
  const { toastDispatch } = useToastContext();
  const [roles, setRoles] = useState(null);
  const [credentials, setCredentials] = useState({
    email: "",
    role: "",
    country:""
  });
  const {
    error: errorRoles,
    loading: loadingRoles,
    sendHttpRequest: getRoles,
  } = useHttp();
  const {
    error: errorPass,
    loading: loadingPass,
    sendHttpRequest: forgotPass,
  } = useHttp();

  const onChange = (e) => {
    const { value, name } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  useEffect(() => {
    getRoles("get_role_sales", "GET", null, ({ data: { roles } }) => {
      setRoles(roles);
      setCredentials({
        ...credentials,
        role: roles.find((role) => role.name.toLowerCase() === "sales")._id,
      });
    });
  }, []);

  const resetPass = async () => {
    
    sessionStorage.clear();

    let data = {
      email: credentials.email,
      role: credentials.role,
      country: credentials.country
    };

    forgotPass("admin_forgot", "POST", data, ({ msg }) => {
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });

     setCredentials({ ...credentials, email: "", country:"" });
    });
  };
  return (
    <Formik
      enableReinitialize
      initialValues={credentials}
      validationSchema={Yup.object().shape({
        country: Yup.string().required("Country is required"),
        email: Yup.string()
          .email("Enter a valid email email")
          .required("Email is required"),
      })}
      onSubmit={() => resetPass()}
    >
      {({ errors, status, touched }) => (
        <div className="page-content">
          <div className="content-wrapper">
            <div className="content d-flex justify-content-center align-items-center">
              <Form className="login-form">
                <div className="card mb-0">
                  <div className="card-body">
                    <div className="text-center mb-3">
                      <i className="icon-spinner11 icon-2x text-warning border-warning border-3 rounded-round p-3 mb-3 mt-1" />
                      <h5 className="mb-0">Password recovery</h5>
                      <span className="d-block text-muted">
                        We'll send you instructions in email
                      </span>
                    </div>
                    <div className="form-group form-group-feedback form-group-feedback-left ">
                      <select
                        className={
                          "form-control" +
                          (errors.country && touched.country
                            ? " is-invalid"
                            : "")
                        }
                        placeholder="Country"
                        name="country"
                        value={credentials.country}
                        onChange={onChange}
                      >
                        <option value="">Select your Country</option>
                        <option value="ke">
                          {" "}
                          {"\uD83C\uDDF0\uD83C\uDDEA"} Kenya
                        </option>
                        <option value="ug">
                          {"\uD83C\uDDFA\uD83C\uDDEC"} Uganda
                        </option>
                        <option value="tz">
                          {"\u{1f1f9}\u{1f1ff}"} Tanzania
                        </option>
                        <option value="in">
                          {"\uD83C\uDDEE\uD83C\uDDF3"} India
                        </option>
                        <option value="ng">
                              {"\uD83C\uDDF3\uD83C\uDDEC"} Nigeria
                            </option>
                      </select>
                      <div className="form-control-feedback">
                        <i className="icon-flag3 text-muted" />
                      </div>

                      <ErrorMessage
                        name="country"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group form-group-feedback form-group-feedback-left">
                      <input
                        type="email"
                        className={
                          "form-control" +
                          (errors.email && touched.email
                            ? " is-invalid"
                            : "")
                        }
                      
                        placeholder="Your email"
                        name="email"
                        value={credentials.email}
                        onChange={onChange}
                        disabled={!roles}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback"
                      />
                      <div className="form-control-feedback">
                        <i className="icon-mail5 text-muted" />
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary btn-block"
                      disabled={loadingPass}
                    >
                      {loadingPass && (
                        <>
                          <div className="lds-ring mr-2">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </>
                      )}
                      Reset Password
                    </button>
                    <div className="text-center mt-3">
                      <Link to="/">Back to Sign In</Link>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
export default RecoverPassword;
